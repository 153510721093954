import React from 'react'
import PageLayout from '../layouts/Page/'
import './index.scss'

export default function Home() {
  return (
  <PageLayout>
    <div className='home'>
      <h1 className='home__choubi'>Aurélien <br />Davennes</h1>
    </div>
  </PageLayout>
  )
}
